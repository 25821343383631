import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MedicationIntakes from "./MedicationIntakes/MedicationIntakes";
import MedicationHistory from "./MedicationHistory/MedicationHistory";
import {
  LEFT_PART_BUTTON_ICON,
  RIGHT_PART_BUTTON_ICON,
} from "../../assets/utils/icons";

import "./PatientPage.css";
import mainApi from "../../assets/api/MainApi";
import MedicalData from "./MedicalData/MedicalData";
import Notes from "./Notes/Notes";
import Preloader from "../Preloader/Preloader";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { formatDataFromApi } from "../../assets/utils/utils";

function PatientPage() {
  const navigate = useNavigate()

  const { patientId, type, medicine_id } = useParams();


  const [typeInfo, setTypeInfo] = useState(type ? type : "medicalData");
  const [userAiID, setUserAiID] = useState('7')

  const [userMedicalData, setUserMedicalData] = useState({
    "_id": "6337eb115ef3340babbaaccc",
    "weight": {
        "type": "kg",
        "value": 107
    },
    "height": {
        "type": "cm",
        "value": 199
    },
    "date_of_birth": "1989-10-01T00:00:00",
    "country": "Great Britain",
    "gender": "male",
    "ai_id": "7"
})
  const [staticIntakesData, setStaticIntakesData] = useState({
    "medications_used": [
        {
            "_id": "6337ec175ef3340babbaacd5",
            "type": "spray",
            "name": "Test med 3"
        },
        {
            "_id": "6337ebc45ef3340babbaacce",
            "type": "pill",
            "name": "TestMed"
        },
        {
            "_id": "6337ebed5ef3340babbaacd2",
            "type": "ointment",
            "name": "Test Med 2"
        }
    ],
    "user": {
        "skipped": "3",
        "intaked": "4",
        "ai_id": "7"
    }
})
  const [indexedIntakesData, setIndexedIntakeData] = useState([
    {
        "_id": "6337ee4f5ef3340babbaacd9",
        "utc_date": "2022-10-01T11:00:00",
        "medicine": {
            "_id": "6337ebc45ef3340babbaacce",
            "name": "TestMed",
            "type": "pill",
            "dosage": "12",
            "dosage_days": 24,
            "dosage_type": "mg"
        },
        "status": "skipped"
    },
    {
        "_id": "6337ee535ef3340babbaacda",
        "utc_date": "2022-10-01T07:37:00",
        "medicine": {
            "_id": "6337ebed5ef3340babbaacd2",
            "name": "Test Med 2",
            "type": "ointment",
            "dosage": "13",
            "dosage_days": 12,
            "dosage_type": "mcg/ml"
        },
        "status": "intaked"
    },
    {
        "_id": "6337ee4b5ef3340babbaacd8",
        "utc_date": "2022-10-01T01:00:00",
        "medicine": {
            "_id": "6337ebc45ef3340babbaacce",
            "name": "TestMed",
            "type": "pill",
            "dosage": "12",
            "dosage_days": 24,
            "dosage_type": "mg"
        },
        "status": "intaked"
    }
])
  const [currentMedication, setCurrentMedication] = useState([
    {
        "_id": "6337ebc45ef3340babbaacce",
        "still_taking": true,
        "dosage": "12",
        "dosage_days": 24,
        "dosage_type": "mg",
        "name": "TestMed",
        "type": "pill",
        "reason": {
            "lack_efficacy": false,
            "side_effects": false,
            "drugs_interaction": false,
            "form_of_use_reason": {
                "difficulty_swallowing": false,
                "bad_flavor": false,
                "other_form_of_use": false
            },
            "cost": false,
            "aviability": false,
            "other_reason": null,
            "medicine_deleted": false
        },
        "frequency": "specific_days"
    },
    {
        "_id": "6337ebed5ef3340babbaacd2",
        "still_taking": true,
        "dosage": "13",
        "dosage_days": 12,
        "dosage_type": "mcg/ml",
        "name": "Test Med 2",
        "type": "ointment",
        "reason": {
            "lack_efficacy": false,
            "side_effects": false,
            "drugs_interaction": false,
            "form_of_use_reason": {
                "difficulty_swallowing": false,
                "bad_flavor": false,
                "other_form_of_use": false
            },
            "cost": false,
            "aviability": false,
            "other_reason": null,
            "medicine_deleted": false
        },
        "frequency": "everyday"
    }
])
  const [discontinuedMedication, setDiscontinuedMedication] = useState([
    {
        "_id": "6337ec175ef3340babbaacd5",
        "still_taking": false,
        "dosage": "4",
        "dosage_days": 44,
        "dosage_type": "mg/cm2",
        "name": "Test med 3",
        "type": "spray",
        "reason": {
            "lack_efficacy": true,
            "side_effects": false,
            "drugs_interaction": true,
            "form_of_use_reason": {
                "difficulty_swallowing": false,
                "bad_flavor": true,
                "other_form_of_use": false
            },
            "cost": false,
            "aviability": true,
            "other_reason": "Not worked for me",
            "medicine_deleted": false
        },
        "frequency": null
    }
])

  const [isPreloaderVisible, setPreloaderVisible] = useState(false)

  const [notes, setNotes] = useState([
    {
        "_id": "6337f6135ef3340babbaacdc",
        "date": "2022-10-01T08:10:59+00:00",
        "text": "Our development plan includes integration of newest and best practices that will help enjoy the app and make it your best companion",
        "name": "Test note",
        "edited": false
    }
])

  useEffect(() => {
    if (type) {
      if (['medicalData', 'intakes', 'history', 'notes'].indexOf(type) === -1) {
        navigate(`/patients/${patientId}/medicalData`)
        setTypeInfo('medicalData')
      } else {
        setTypeInfo(type)
      }

    }
    else {
      // navigate(`/patients/${patientId}/medicalData`)
      // setTypeInfo('medicalData')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, patientId])

  const [userSyndromes, setUserSyndromes] = useState([{
    "syndromes_id": "62f2723d5eec362facc09daa",
    "items": [
        {
            "name": "Epilepsy type",
            "key": "epilepsy_type",
            "value": "Focal aware tonic",
            "type": "array_choose_one",
            "default": "Epilepsy type",
            "choose_values": [
                "Focal aware clonic",
                "Generalized tonic",
                "Focal aware tonic",
                "Absense",
                "Focal aware sensory",
                "Myoclonic",
                "Focal Impared Awareness",
                "Hyperkinetic",
                "Focal to bilateral to tonik clonic",
                "Epileptic spasms",
                "Generalized tonic clonic",
                "Drop tonic",
                "Generalized clonic",
                "Drop atonic"
            ]
        }
    ],
    "_id": "6337eb7d51e56824a7269914",
    "name": "Epilepsy"
}])
  const [userSymptomes, setUserSymptomes] = useState([{
    "symptoms_id": "62f2723d5eec362facc09dab",
    "items": [
        {
            "name": "Seizure frequency",
            "key": "seizure_frequency",
            "value": "Two or three months",
            "type": "array_choose_one",
            "default": "Seizure frequency",
            "choose_values": [
                "A year or more",
                "Half a year",
                "Two or three months",
                "Month",
                "Two to three weeks",
                "Week or two",
                "Week",
                "Day"
            ]
        },
        {
            "key": "seizure_number_and_duration",
            "value": {
                "amount": 12,
                "duration": {
                    "minutes": 11,
                    "seconds": 4
                }
            },
            "type": "seizure_number_and_duration"
        }
    ],
    "_id": "6337eb8e51e56824a7269978",
    "name": "Seizures"
}])

  useEffect(() => {
    if (patientId && typeInfo) {
      console.log(typeInfo)
      // // setPreloaderVisible(true)
      switch (typeInfo) {
        

        

       

      

        default:
          break;
      }


    }

    // setData(patientDetailsData);
  }, [patientId, typeInfo, medicine_id]);

  return (
    <section className="patient">
      <div className="patient__title-box">
        <p className="patient__title-text">Patient ID:</p>
        {userAiID ? <p className="patient__id-text">{userAiID}</p> : <></>}
        {!userAiID && isPreloaderVisible ?
          <div className="patient__id-preloader">
            <MiniPreloader isLinkColor={false} />
          </div>
          : <></>}
      </div>

      <div className="patient__profile">
        <div className="patient__profile-nav">
          <Link className={`patient__nav-button patient__nav-button_type_medicalData ${typeInfo === "medicalData" ? "patient__nav-button_active" : ""
            }`} to={`/patients/${patientId}/medicalData`}
            onClick={() => {
              // // setPreloaderVisible(true)
              setTypeInfo("medicalData")
            }}
          >
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Medical Data</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>


          <Link className={`patient__nav-button patient__nav-button_type_intakes ${typeInfo === "intakes" ? "patient__nav-button_active" : ""
            }`}
            to={`/patients/${patientId}/intakes`}
            onClick={() => {
              // // setPreloaderVisible(true)
              setTypeInfo("intakes")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Medication Intakes</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>

          <Link className={`patient__nav-button patient__nav-button_type_history ${typeInfo === "history" ? "patient__nav-button_active" : ""
            }`}
            to={`/patients/${patientId}/history`}
            onClick={() => {
              // setPreloaderVisible(true)
              setTypeInfo("history")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Medication History</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>

          <Link className={`patient__nav-button patient__nav-button_type_notes ${typeInfo === "notes" ? "patient__nav-button_active" : ""
            }`}
            to={`/patients/${patientId}/notes`}
            onClick={() => {
              // setPreloaderVisible(true)
              setTypeInfo("notes")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Notes</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>

          <button
            className={`patient__nav-button patient__nav-button_type_analysis patient__nav-button_type_analysis_disabled ${typeInfo === "analysis" ? "patient__nav-button_active" : ""
              }`}
            type="button"
            onClick={() => {
              // setPreloaderVisible(true)
              setTypeInfo("analysis")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Analysis</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </button>
        </div>

        <div className="patient__profile-content">
          {isPreloaderVisible ?
            <div className="patient__profile-preloader">
              <Preloader />
            </div> :
            <>
              {typeInfo === "medicalData" ? (
                <MedicalData data={userMedicalData} setUserSyndromes={setUserSyndromes} userSyndromes={userSyndromes} userSymptomes={userSymptomes} patientId={patientId} />
              ) : null}

              {typeInfo === "intakes" ? (
                <MedicationIntakes medicine_id={medicine_id} patientId={patientId} setIndexedIntakeData={setIndexedIntakeData} staticData={staticIntakesData} indexedData={indexedIntakesData} />
              ) : null}

              {typeInfo === "history" ? (
                <MedicationHistory setDiscontinuedMedication={setDiscontinuedMedication} setCurrentMedication={setCurrentMedication} patientId={patientId} discontinuedMedication={discontinuedMedication} currentMedication={currentMedication} {...{ setTypeInfo }} />
              ) : null}

              {typeInfo === "notes" ? (
                <Notes patientId={patientId} {...{ notes, setNotes }} />
              ) : null}

              {typeInfo === "analysis" ? (
                <div className="patient__analysis"></div>
              ) : null}
            </>
          }

        </div>
      </div>
    </section>
  );
}

export default PatientPage;
