import { Link, useLocation } from "react-router-dom";
import { LOGO_ICON, BACK_ARROW_ICON } from "../../assets/utils/icons";
import "./Header.css";

function Header({ setPopupOpen }) {
  const location = useLocation();

  return (
    <header className="header">
       <Link to="/" className="header__logo">
          <div className="header__logo-icon">{LOGO_ICON}</div>
          <p className="header__logo-text">ZAM</p>
        </Link>
      <div className="header__content-box">
        {location.pathname !== "/" ? (
          <Link to="/" className="header__back-button">
            <div className="header__back-icon">{BACK_ARROW_ICON}</div>
            <p className="header__back-text">Back to list</p>
          </Link>
        ) : null}

       

        <button
          className="header__logout-button"
          type="button"
          onClick={() => setPopupOpen(true)}
        >
          log out
        </button>
      </div>
    </header>
  );
}

export default Header;
