import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "../Popup/Popup";
import {
  BETTER_ICON,
  NEUTRAL_ICON,
  WORSE_ICON,
  PATIENT_LINK_ICON,
} from "../../assets/utils/icons";

import "./Main.css";

import pillIcon from '../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../assets/images/main/med_type/drops.png'
import procedureIcon from '../../assets/images/main/med_type/procedure.png'
import ointmentIcon from '../../assets/images/main/med_type/ointment.png'



import mainApi from "../../assets/api/MainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";



function Main({ patients, setPatients }) {

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [patient, setPatient] = useState("");



  function openPopup(patient) {
    console.log(patient)
    setPopupOpen(true);
    setPatient(patient);
  }

  function handleReminderClick() {
    // console.log(`send reminder to patient ${patient}`);
    console.log({patient})
    mainApi.sendRemainder({user_id: patient.user_id, medicine_intake_id: patient._id, })
    .then((res)=>{
      setPatients(prevItems => prevItems.map((item)=>{
        if(item._id === patient._id){
          return {
            ...item,
            allow_send_reminder: false
          }
        } else return item
      }))
      console.log(res)
    })
    .catch((err)=>{
      console.log(err)
    })
    setPopupOpen(false);
  }

  const listInnerRef = useRef();

  const [pageValue, setPageValue] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false)

  const [scrollTraking, setScrollTraking] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && patients && patients.length > 0) {
      setPrevScrollPosition(scrollPosition)
      const { scrollHeight } = listInnerRef.current;
      if (scrollHeight < scrollPosition + 800) {
        setScrollTraking(false)
        setPageValue(pageValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, patients]);

  useEffect(() => {

    if (pageValue > 0 && patients && patients.length === 20 * pageValue) {
      let prevId = patients[patients.length - 1]._id
      let prevIntakesMissed = patients[patients.length - 1].intakes_missed_last_week
      console.log(prevId, prevIntakesMissed)
      console.log('ss')
      setPreloaderVisible(true)
      mainApi.getPatients({ limit: 20, last_id: prevId, last_intakes_skips: prevIntakesMissed })
        .then((res) => {
          console.log(res.patients)
          setPreloaderVisible(false)
          setPatients(prewList => prewList.concat(res.patients))
        })
        .catch((err) => {
          setPreloaderVisible(false)
          console.log(err)
        })

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue, patients])

  return (
    <main className="main">
      <h1 className="main__title">Current patients</h1>
      {patients !== undefined ?
        <table className="main__table" ref={listInnerRef}>
          <thead className="main__table-head">
            <tr className="main__table-tr-head">
              <th
                className="main__table-title main__table-title_type_id"
                colSpan="1"
              >
                id
              </th>
              <th
                className="main__table-title main__table-title_type_diagnosis"
                colSpan="1"
              >
                Diagnosis
              </th>
              <th
                className="main__table-title main__table-title_type_medicine"
                colSpan="1"
              >
                Medications used
              </th>
              <th
                className="main__table-title main__table-title_type_questionare"
                colSpan="1"
              >
                Daily questionnaire results
              </th>
              <th
                className="main__table-title main__table-title_type_intake"
                colSpan="1"
              >
                Medication intake (7d course)
              </th>
              <th
                className="main__table-title main__table-title_type_patient"
                colSpan="1"
              >
                Patient details
              </th>
              <th
                className="main__table-title main__table-title_type_reminder"
                colSpan="1"
              />
            </tr>
          </thead>

          <tbody className="main__table-body">
            {patients.length !== 0
              ? patients.map((patient) => (
                <tr className="main__table-tr-body" key={patient._id}>
                  <th className="main__table-th-body main__table-th-body_type_id">
                    <p className="main__table-id-text">{patient.ai_id}</p>
                  </th>
                  <th className="main__table-th-body main__table-th-body_type_diagnosis">
                    <p className="main__table-text">{patient.syndrome_text}</p>
                  </th>
                  <th className="main__table-th-body main__table-th-body_type_medicine" title={patient.medicine.name}>
                    <div className="main__medicine-box">
                      {patient.medicine.type === 'pill' && <img className="main__medicine-image" src={pillIcon} alt='' />}
                      {patient.medicine.type === 'injection' && <img className="main__medicine-image" src={injectionIcon} alt='' />}
                      {patient.medicine.type === 'syrup' && <img className="main__medicine-image" src={syrupIcon} alt='' />}
                      {patient.medicine.type === 'spray' && <img className="main__medicine-image" src={sprayIcon} alt='' />}
                      {patient.medicine.type === 'tablet' && <img className="main__medicine-image" src={tabletIcon} alt='' />}
                      {patient.medicine.type === 'drops' && <img className="main__medicine-image" src={dropsIcon} alt='' />}
                      {patient.medicine.type === 'ointment' && <img className="main__medicine-image" src={ointmentIcon} alt='' />}
                      {patient.medicine.type === 'procedure' && <img className="main__medicine-image" src={procedureIcon} alt='' />}
                      <p className="main__medicine-text">
                        {patient.medicine.name}
                      </p>
                    </div>
                  </th>
                  <th className="main__table-th-body main__table-th-body_type_questionare">
                    <div className="main__result-box">
                      <div className="main__result-icon">
                        {patient.daily_questionnaire_results === "better"
                          ? BETTER_ICON
                          : patient.daily_questionnaire_results === "neutral"
                            ? NEUTRAL_ICON
                            : WORSE_ICON}
                      </div>
                      <p className="main__table-text">
                        {patient.daily_questionnaire_results.substring(0,1).toUpperCase() + patient.daily_questionnaire_results.substring(1)}
                      </p>
                    </div>
                  </th>
                  <th className="main__table-th-body main__table-th-body_type_intake">
                    <div
                      className={`main__intake-box ${patient.intakes_missed_last_week === 0
                        ? "main__intake-box_type_no-skips"
                        : ""
                        } ${patient.intakes_missed_last_week === 1
                          ? "main__intake-box_type_skip-once"
                          : ""
                        } ${patient.intakes_missed_last_week === 2
                          ? "main__intake-box_type_skip-twice"
                          : ""
                        } ${patient.intakes_missed_last_week > 2
                          ? "main__intake-box_type_skip-often"
                          : ""
                        }`}
                    >
                      <p className="main__table-text">
                        {patient.intakes_missed_last_week === 0 ? 'No skips' : ''}
                        {patient.intakes_missed_last_week === 1 ? 'Skipped once' : ''}
                        {patient.intakes_missed_last_week === 2 ? 'Skipped twice' : ''}
                        {patient.intakes_missed_last_week > 2 ? 'Skipped often' : ''}
                      </p>
                    </div>
                  </th>
                  <th className="main__table-th-body main__table-th-body_type_reports">
                    <Link
                      className="main__patient-link"
                      to={`/patients/${patient.user_id}/medicalData`}
                    >
                      {PATIENT_LINK_ICON}
                    </Link>
                  </th>
                  <th className="main__table-th-body main__table-th-body_type_reports">
                    {patient.allow_send_reminder ? (
                      <button
                        className="main__reminder-button"
                        type="button"
                        onClick={() => openPopup(patient)}
                      >
                        send reminder
                      </button>
                    ) : null}
                  </th>
                </tr>
              ))
              : null}
          </tbody>
          {isPreloaderVisible ? <div className="main__table-preloader">
            <MiniPreloader isLinkColor={false} />
          </div> : <></>}

        </table>
        : <></>}



      <Popup
        popup="reminder"
        {...{ isPopupOpen, setPopupOpen }}
        onClick={handleReminderClick}
      >
        <div className="popup__id-box">
          <p className="popup__id-text">Patient ID:</p>
          <p className="popup__id">{patient.ai_id}</p>
        </div>
        <p className="popup__text">
          Send reminder to patient that he/she has skipped the medication
          intake?
        </p>
      </Popup>
    </main>
  );
}

export default Main;
