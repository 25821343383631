import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Header/Header";
import Auth from "../Auth/Auth";
import Main from "../Main/Main";
import PatientPage from "../PatientPage/PatientPage";
import Popup from "../Popup/Popup";
import "./App.css";
import mainApi from "../../assets/api/MainApi";
import Preloader from '../Preloader/Preloader'




function App() {



  const [isLoggedIn, setLoggedIn] = useState(true);
  const [currentUser, setCurrentUser] = useState({
    _id: "632b25382e081c0982ce267c",
    email: "user@example.com",
    last_name: "Wong",
    name: "Galaxy",
    middle_name: null,
    clinic_id: "632b23d92e081c0982ce2676"
})
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [patients, setPatients] = useState([
    {
        "_id": "6337ebc45ef3340babbaaccf",
        "user_id": "6337eb115ef3340babbaaccc",
        "syndrome_text": "Epilepsy",
        "medicine": {
            "name": "TestMed",
            "type": "pill"
        },
        "daily_questionnaire_results": "neutral",
        "intakes_missed_last_week": 0,
        "allow_send_reminder": false,
        "ai_id": "7"
    },
    {
        "_id": "6337ebc45ef3340babbaacd0",
        "user_id": "6337eb115ef3340babbaaccc",
        "syndrome_text": "Epilepsy",
        "medicine": {
            "name": "TestMed",
            "type": "pill"
        },
        "daily_questionnaire_results": "neutral",
        "intakes_missed_last_week": 0,
        "allow_send_reminder": false,
        "ai_id": "7"
    },
    {
        "_id": "6337ebc45ef3340babbaacd1",
        "user_id": "6337eb115ef3340babbaaccc",
        "syndrome_text": "Epilepsy",
        "medicine": {
            "name": "TestMed",
            "type": "pill"
        },
        "daily_questionnaire_results": "neutral",
        "intakes_missed_last_week": 1,
        "allow_send_reminder": false,
        "ai_id": "7"
    },
    {
        "_id": "6337ebed5ef3340babbaacd3",
        "user_id": "6337eb115ef3340babbaaccc",
        "syndrome_text": "Epilepsy",
        "medicine": {
            "name": "Test Med 2",
            "type": "ointment"
        },
        "daily_questionnaire_results": "better",
        "intakes_missed_last_week": 2,
        "allow_send_reminder": false,
        "ai_id": "7"
    },
    {
        "_id": "6337ebed5ef3340babbaacd4",
        "user_id": "6337eb115ef3340babbaaccc",
        "syndrome_text": "Epilepsy",
        "medicine": {
            "name": "Test Med 2",
            "type": "ointment"
        },
        "daily_questionnaire_results": "worse",
        "intakes_missed_last_week": 3,
        "allow_send_reminder": false,
        "ai_id": "7"
    }
])

  function logout() {
    console.log("logout")


    mainApi.logout()
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoggedIn(false)
        setPopupOpen(false)
      })

  }

  // useEffect(() => {
  //   // console.log(moment().format('x'))
  //   mainApi.getMe()
  //     .then((res) => {
  //       setCurrentUser(res)
  //       setLoggedIn(true)
  //       console.log(res)
  //     })
  //     .catch((err) => {
  //       setLoggedIn(false)
  //       console.log(err)
  //     })
  // }, [])

  // useEffect(() => {
  //   if (currentUser) {
  //     mainApi.getPatients({ limit: 20 })
  //       .then((res) => {
  //         console.log(res.patients)
  //         setPatients(res.patients)
  //       })
  //       .catch((err) => {
  //         setPatients([])
  //         console.log(err)
  //       })
  //   }
  // }, [currentUser])

  return (
    <div className="app">
      {isLoggedIn === undefined ?
        <div className='app__preloader'>
          <Preloader />

        </div>

        : <></>}


      {isLoggedIn ? <Header {...{ setPopupOpen }} /> : null}

      {isLoggedIn ?
        <Routes>
          <Route
            path="/login"
            element={<Auth {...{ isLoggedIn, setLoggedIn, setCurrentUser }} />}
          />
          <Route exact path="/" element={<Main {...{ patients, setPatients }} />} />
          <Route path="/patients/:patientId" element={<PatientPage />} />
          <Route path="/patients/:patientId/:type" element={<PatientPage />} />
          <Route path="/patients/:patientId/:type/:medicine_id" element={<PatientPage />} />

        </Routes>
        : isLoggedIn !== undefined ?
          <Routes>
            <Route
              path="*"
              element={<Auth {...{ isLoggedIn, setLoggedIn, setCurrentUser }} />}
            />
          </Routes>
          :
          <></>
      }

      <Popup popup="logout" {...{ isPopupOpen, setPopupOpen }} onClick={logout}>
        <p className="popup__title">
          Are you sure you want
          <br />
          to log out?
        </p>
      </Popup>
    </div>
  );
}

export default App;
