// import moment from "moment-timezone"
// import Cookies from 'js-cookie';
import { MAIN_URL } from "../utils/constants"





class MainApi {
    constructor({ baseUrl }) {
        this._BASE_URL = baseUrl
    }

    login({ email, password }) {
        return fetch(`${this._BASE_URL}/doctors/user/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
                "password": password
            })
        }).then(this._checkResponse)
    };

    logout() {
        return fetch(`${this._BASE_URL}/doctors/user/logout`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(this._checkResponse)
    };

    getMe() {
        return fetch(`${MAIN_URL}/doctors/user/get-me`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getMe,
            params: {}
        }))
    };

    getPatients({ last_id, limit, last_intakes_skips }) {
        let params = {}
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit
        if (last_intakes_skips !== undefined) params.last_intakes_skips = last_intakes_skips


        return fetch(`${MAIN_URL}/doctors/patients/get-patients?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatients,
            params: { last_id, limit, last_intakes_skips }
        }))
    };

    getPatientMedicalData({ user_id }) {
        let params = {}
        if (user_id) params.user_id = user_id


        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medical-data/patient?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatientMedicalData,
            params: { user_id }
        }))
    };

    getPatientMedicalDataSyndromes({ user_id, last_id, limit }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit


        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medical-data/syndromes-indexed?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatientMedicalDataSyndromes,
            params: { user_id, last_id, limit }
        }))
    };

    getPatientMedicalDataSymptomes({ user_id, last_id, limit }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit


        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medical-data/symptoms-indexed?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatientMedicalDataSymptomes,
            params: { user_id, last_id, limit }
        }))
    };

    getPatientMedicalIntakesStatic({ user_id }) {
        let params = {}
        if (user_id) params.user_id = user_id


        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medication-intake/static?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatientMedicalIntakesStatic,
            params: { user_id }
        }))
    };

    getPatientMedicalIntakesIndexed({ user_id, last_id, limit, last_date, medicine_id }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit
        if (last_date) params.last_date = last_date
        if (medicine_id) params.medicine_id = medicine_id

        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medication-intake/indexed?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatientMedicalIntakesIndexed,
            params: { user_id, last_id, limit, last_date, medicine_id }
        }))
    };

    getPatientMedicationHistory({ user_id, last_id, limit, still_taking }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit
        if (still_taking !== undefined) params.still_taking = still_taking

        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medication-history?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getPatientMedicationHistory,
            params: { user_id, last_id, limit, still_taking }
        }))
    };


    getMedicineInfoStatic({ user_id, medication_id }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (medication_id) params.medication_id = medication_id

        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medication/static?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getMedicineInfoStatic,
            params: { user_id, medication_id }
        }))
    };

    getMedicineInfoIndexed({ user_id, last_id, limit, medication_id, last_date }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit
        if (medication_id) params.medication_id = medication_id
        if (last_date) params.last_date = last_date

        return fetch(`${MAIN_URL}/doctors/patients/get-patient/medication/indexed?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getMedicineInfoIndexed,
            params: { user_id, last_id, limit, medication_id, last_date }
        }))
    };

    getNotes({ user_id, last_id, limit }) {
        let params = {}
        if (user_id) params.user_id = user_id
        if (last_id) params.last_id = last_id
        if (limit) params.limit = limit

        return fetch(`${MAIN_URL}/doctors/patients/get-patient/notes/indexed?` + new URLSearchParams(params), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getNotes,
            params: { user_id, last_id, limit }
        }))
    };


    addNote({ user_id, text, name }) {
        return fetch(`${MAIN_URL}/doctors/patients/get-patient/notes/create`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": user_id,
                "text": text,
                "name": name
            })
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.addNote,
            params: { user_id, text, name }
        }))
    };

    editNote({ user_id, text, name, note_id }) {
        return fetch(`${MAIN_URL}/doctors/patients/get-patient/notes/patch`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": user_id,
                "note_id": note_id,
                "text": text,
                "name": name
            })
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.editNote,
            params: { user_id, text, name, note_id }
        }))
    };

    deleteNote({ user_id, note_id }) {
        return fetch(`${MAIN_URL}/doctors/patients/get-patient/notes/delete`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": user_id,
                "note_id": note_id,
            })
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.deleteNote,
            params: { user_id, note_id }
        }))
    };

    changeSyndrom({ user_id, user_syndrome_id, syndrome_id, data }) {
        return fetch(`${this._BASE_URL}/doctors/patients/get-patient/medical-data/syndromes-edit`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": user_id,
                "user_syndrome_id": user_syndrome_id,
                "syndrome_id": syndrome_id,
                "data": data,
            })
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.changeSyndrom,
            params: { user_id, user_syndrome_id, syndrome_id, data }
        }))
    };

    sendRemainder({ user_id, medicine_intake_id }) {
        return fetch(`${this._BASE_URL}/doctors/patients/send-notification`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "user_id": user_id,
                "medicine_intake_id": medicine_intake_id,
                "medicine_intake_in": medicine_intake_id

            })
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.sendRemainder,
            params: { user_id, medicine_intake_id }
        }))
    };

    refreshJWT() {
        return fetch(`${this._BASE_URL}/doctors/user/refresh-jwt`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'csrf_access_token': Cookies.get('csrf_access_token'),
                // 'csrf_refresh_token': Cookies.get('csrf_refresh_token')
            },
        }).then(this._checkResponse)
    };





    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        else {
            var statusCode = res.status
            return res.json().then((res) => {
                return Promise.reject({
                    statusCode: statusCode,
                    message: res.message,
                    detail: res.detail
                })
            })
        }
    }

    _checkResponseWithCookies({ res, func, params }) {
        if (res.ok) {
            return res.json();
        }
        else {
            var statusCode = res.status
            return res.json().then((res) => {
                if (statusCode === 422 && res.detail === 'Signature has expired') {
                    console.log('ss')
                    return mainApi.refreshJWT()
                        .then((res) => {
                            return func(params)
                        })
                        .catch((err) => {
                            return Promise.reject(err)
                        })


                } else {
                    return Promise.reject({
                        statusCode: statusCode,
                        message: res.message,
                        detail: res.detail
                    })
                }

            })
        }

    }
}

const mainApi = new MainApi({
    baseUrl: MAIN_URL
    // baseUrl: 'http://localhost:3003'
});
export default mainApi

