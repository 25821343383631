import { useEffect, useRef } from "react";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import "./DeliteNotePopup.css";

function DeliteNotePopup({ popup, isPopupOpen, setPopupOpen, children, onClick, onCancel, isPreloaderVisible, isPreloaderLinkColor }) {
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  return (
    <div className={`popup ${isPopupOpen ? "popup_opened" : ""}`}>
      <div className="popup__container">
        {children}
        <div className="popup__buttons-box">
          <button
            className={`popup__button popup__button_type_cancel-${popup}`}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={`popup__button popup__button_type_sure-${popup}`}
            type="button"
            onClick={onClick}
          >
            {isPreloaderVisible ? <MiniPreloader isLinkColor={isPreloaderLinkColor} /> : 'Delete'}
            
          </button>
        </div>
      </div>
      <div
        className="popup__overlay"
        ref={overlay}
        onTouchStart={onCancel}
      />
    </div>
  );
}

export default DeliteNotePopup;
