export const LOGO_ICON = (
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6839 1.50634C11.5958 0.981355 11.1602 0.584616 10.6293 0.545853C10.0984 0.507091 9.60976 0.836327 9.44637 1.34297L6.22909 11.3187H2.07839C1.44075 11.3187 0.923828 11.8356 0.923828 12.4733C0.923828 13.1109 1.44075 13.6278 2.07839 13.6278H7.06984C7.57097 13.6278 8.01486 13.3046 8.16868 12.8276L10.1902 6.55972L13.0219 23.4403C13.1111 23.9723 13.5568 24.3716 14.0954 24.4019C14.6341 24.4324 15.1219 24.0857 15.2705 23.5673L19.0105 10.513L20.9808 17.4088C21.1076 17.8531 21.4874 18.179 21.946 18.237C22.4044 18.2951 22.8535 18.074 23.087 17.6751L25.4582 13.6278H27.2533C27.7559 15.4048 29.3896 16.7067 31.3274 16.7067C33.6655 16.7067 35.5608 14.8113 35.5608 12.4733C35.5608 10.1352 33.6655 8.23989 31.3274 8.23989C29.3896 8.23989 27.7559 9.5419 27.2533 11.3187H24.7966C24.3866 11.3187 24.0074 11.5361 23.8004 11.8897L22.4594 14.1786L20.1221 5.99844C19.9806 5.50293 19.5277 5.16124 19.0125 5.16105C18.4971 5.16085 18.044 5.50222 17.9021 5.99762L14.4553 18.0281L11.6839 1.50634ZM29.4031 12.4733C29.4031 11.4106 30.2646 10.549 31.3274 10.549C32.3902 10.549 33.2517 11.4106 33.2517 12.4733C33.2517 13.5361 32.3902 14.3975 31.3274 14.3975C30.2646 14.3975 29.4031 13.5361 29.4031 12.4733Z"
      fill="#2D6956"
    />
  </svg>
);

export const PASSWORD_HIDDEN_ICON = (

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z" fill="black" />
  </svg>

);

export const PASSWORD_SHOWN_ICON = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6C15.79 6 19.17 8.13 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.11 17 4 12 4C10.73 4 9.51 4.2 8.36 4.57L10.01 6.22C10.66 6.09 11.32 6 12 6ZM10.93 7.14L13 9.21C13.57 9.46 14.03 9.92 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.01 14.48 7 12 7C11.63 7 11.28 7.05 10.93 7.14ZM2.01 3.87L4.69 6.55C3.06 7.83 1.77 9.53 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.45L2.01 3.87ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37V11.37ZM6.11 7.97L7.86 9.72C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.89 6.11 7.97Z" fill="black" />
  </svg>

);

export const BACK_ARROW_ICON = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7.5H3.83L9.42 1.91L8 0.5L0 8.5L8 16.5L9.41 15.09L3.83 9.5H16V7.5Z"
      fill="#323232"
    />
  </svg>
);

export const BETTER_ICON = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0L0 5.66802L1.41 7L6 2.67341L10.59 7L12 5.66802L6 0Z"
      fill="#2D6956"
    />
  </svg>
);

export const NEUTRAL_ICON = (
  <svg
    width="10"
    height="2"
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95426 3.24249e-05L0.364285 -1.52588e-05L0.364258 1.33197H4.81851H9.54426L9.54432 -1.52588e-05L4.95426 3.24249e-05Z"
      fill="#FFC531"
    />
  </svg>
);

export const WORSE_ICON = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 7L12 1.33198L10.59 -1.23266e-07L6 4.32659L1.41 -9.25807e-07L4.95514e-07 1.33198L6 7Z"
      fill="#AE1500"
    />
  </svg>
);

export const PATIENT_LINK_ICON = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 8.375 15.125 8 14.6667 8C14.2083 8 13.8333 8.375 13.8333 8.83333V13C13.8333 13.4583 13.4583 13.8333 13 13.8333ZM9.66667 1.33333C9.66667 1.79167 10.0417 2.16667 10.5 2.16667H12.6583L5.05 9.775C4.725 10.1 4.725 10.625 5.05 10.95C5.375 11.275 5.9 11.275 6.225 10.95L13.8333 3.34167V5.5C13.8333 5.95833 14.2083 6.33333 14.6667 6.33333C15.125 6.33333 15.5 5.95833 15.5 5.5V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H10.5C10.0417 0.5 9.66667 0.875 9.66667 1.33333Z"
      fill="#414945"
    />
  </svg>
);

export const LEFT_PART_BUTTON_ICON = (
  <svg
    className="left-nav-card-icon"
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="left-nav-card-icon-fill"
      d="M45 0V44H0C7.29249 42.4375 12.0315 41.2499 19.4466 18.4375C25.2569 0.562405 37.7075 -2.48864e-05 45 0Z"
      fill="#F3F7F3"
    />
  </svg>
);

export const RIGHT_PART_BUTTON_ICON = (
  <svg
    className="right-nav-card-icon"
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_86_1040)">
      <path
        className="right-nav-card-icon-fill"
        d="M0 4V48H45C37.7075 46.4375 32.9685 45.2499 25.5534 22.4375C19.7431 4.5624 7.29249 3.99998 0 4Z"
        fill="#E7EEEA"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_86_1040"
        x="0"
        y="0"
        width="61"
        height="60"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_86_1040"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_86_1040"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ACTIONS_BUTTON_ICON = (
  <svg
    width="4"
    height="14"
    viewBox="0 0 4 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 6.69644C3.5 7.50572 2.82834 8.16193 2 8.16193C1.17166 8.16193 0.5 7.50572 0.5 6.69644C0.5 5.88716 1.17166 5.23096 2 5.23096C2.82834 5.23096 3.5 5.88716 3.5 6.69644Z"
      fill="#303030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.76529C3.5 2.57457 2.82834 3.23077 2 3.23077C1.17166 3.23077 0.5 2.57457 0.5 1.76529C0.5 0.956007 1.17166 0.299805 2 0.299805C2.82834 0.299805 3.5 0.956007 3.5 1.76529Z"
      fill="#303030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 11.6276C3.5 12.4369 2.82834 13.0931 2 13.0931C1.17166 13.0931 0.5 12.4369 0.5 11.6276C0.5 10.8183 1.17166 10.1621 2 10.1621C2.82834 10.1621 3.5 10.8183 3.5 11.6276Z"
      fill="#303030"
    />
  </svg>
);

export const CLOSE_ICON = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6846 2.3335L2.33291 11.6852"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M2.33301 2.3335L11.6847 11.6852"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
