import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import mainApi from "../../assets/api/MainApi";
import {
  LOGO_ICON,
  PASSWORD_HIDDEN_ICON,
  PASSWORD_SHOWN_ICON,
} from "../../assets/utils/icons";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import "./Auth.css";

function Auth({ setCurrentUser, setLoggedIn }) {
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [touched, setTouched] = useState({ email: false, password: false });
  const [isInputValid, setInputValid] = useState({
    email: { errorMessage: "", validState: false },
    password: { errorMessage: "", validState: false },
  });
  const [isFormValid, setFormValid] = useState(false);

  function checkValidity(name, value) {
    if (!value) {
      setInputValid({
        ...isInputValid,
        [name]: {
          errorMessage: `${name
            .slice(0, 1)
            .toUpperCase()
            .concat(name.slice(1))} is required`,
          validState: false,
        },
      });
    } else {
      if (name === "email") {
        if (validator.isEmail(value)) {
          setInputValid({
            ...isInputValid,
            [name]: {
              errorMessage: "",
              validState: true,
            },
          });
        } else {
          setInputValid({
            ...isInputValid,
            [name]: {
              errorMessage: "Enter a valid email",
              validState: false,
            },
          });
        }
      }

      if (name === "password") {
        if (value.length > 8) {
          setInputValid({
            ...isInputValid,
            [name]: {
              errorMessage: "",
              validState: true,
            },
          });
        } else {
          setInputValid({
            ...isInputValid,
            [name]: {
              errorMessage: "Minimum 8 characters",
              validState: false,
            },
          });
        }
      }
    }

    isInputValid.email.validState && isInputValid.password.validState
      ? setFormValid(true)
      : setFormValid(false);
  }

  useEffect(() => {


    if (validator.isEmail(values.email)) {
      setInputValid({
        ...isInputValid,
        email: {
          errorMessage: "",
          validState: true,
        },
      });
    } else {
      setInputValid({
        ...isInputValid,
        email: {
          errorMessage: "Enter a valid email",
          validState: false,
        },
      });
    }

    if (values.password.length > 8) {
      setInputValid({
        ...isInputValid,
        password: {
          errorMessage: "",
          validState: true,
        },
      });
    } else {
      setInputValid({
        ...isInputValid,
        password: {
          errorMessage: "Minimum 8 characters",
          validState: false,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    isInputValid.email.validState && isInputValid.password.validState
      ? setFormValid(true)
      : setFormValid(false)
  }, [isInputValid])

  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;
    setValues({ ...values, [name]: value });
    checkValidity(name, value);
  }

  function handleBlur(evt) {
    const name = evt.target.name;
    setTouched({ ...touched, [name]: true });
  }

  const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [loginError, setLoginError] = useState('')
  function handleSubmit(evt) {
    evt.preventDefault();
    console.log(values);
    if (!loginError && !isPreloaderVisible) {
      setPreloaderVisible(true)
      mainApi.login({ email: values.email, password: values.password })
        .then((res) => {
          console.log(res)
          setCurrentUser(res)
          setLoggedIn(true)
        })
        .catch((err) => {
          setLoggedIn(false)
          switch (err.detail) {
            case 'user not found':
              setLoginError('Invalid email address or password')
              setTimeout(() => {
                setLoginError('')
              }, 2000);
              break;

            default:
              break;
          }
          console.log(err)
        })
        .finally(() => {
          setPreloaderVisible(false)
          navigate("/");
        })
    }


  }

  return (
    <section className="auth">
      <div className="auth__container">
        <div className="auth__logo-block">
          <div className="auth__logo-box">
            <div className="auth__logo-icon">{LOGO_ICON}</div>
            <p className="auth__logo-text">ZAM</p>
          </div>
        </div>

        <form className="auth__form" onSubmit={handleSubmit}>
          <h2 className="auth__title">Log in</h2>
          <div className="auth__inputs-block">
            <div className="auth__input-box">
              <label className="auth__label" htmlFor="email">
                E-mail
              </label>
              <input
                className={`auth__input auth__input_type_email ${touched.email && !isInputValid.email.validState
                  ? "auth__input_error"
                  : ""
                  }`}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && !isInputValid.email.validState ? (
                <span className="auth__error-message">
                  {isInputValid.email.errorMessage}
                </span>
              ) : null}
            </div>

            <div className="auth__input-box">
              <label className="auth__label" htmlFor="password">
                Password
              </label>
              <input
                className={`auth__input auth__input_type_password ${touched.password && !isInputValid.password.validState
                  ? "auth__input_error"
                  : ""
                  }`}
                id="password"
                name="password"
                type={!isPasswordVisible ? "password" : "text"}
                autoComplete="on"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <button
                className="auth__input-icon"
                type="button"
                onClick={() => setPasswordVisible(!isPasswordVisible)}
              >
                {!isPasswordVisible
                  ? PASSWORD_HIDDEN_ICON
                  : PASSWORD_SHOWN_ICON}
              </button>
              {touched.password && !isInputValid.password.validState ? (
                <span className="auth__error-message">
                  {isInputValid.password.errorMessage}
                </span>
              ) : null}
            </div>
          </div>

          <button
            className={`auth__submit-button ${!isFormValid || loginError  ? "auth__submit-button_disabled" : ""
              }`}
            type="submit"
          >
            {isPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : ' Log in'}

          </button>
          <p className="auth__submit-error">{loginError}</p>
        </form>
      </div>
    </section>
  );
}

export default Auth;
