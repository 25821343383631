import "./MedicationIntakes.css";

import pillIcon from '../../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../../assets/images/main/med_type/drops.png'
import procedureIcon from '../../../assets/images/main/med_type/procedure.png'
import ointmentIcon from '../../../assets/images/main/med_type/ointment.png'

import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import mainApi from "../../../assets/api/MainApi";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { Link } from "react-router-dom";



function MedicationIntakes({ data = [], staticData, indexedData, patientId, setIndexedIntakeData, medicine_id }) {


  const listInnerRef = useRef();

  const [pageValue, setPageValue] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [scrollTraking, setScrollTraking] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && indexedData && indexedData.length > 0) {
      setPrevScrollPosition(scrollPosition)
      const { scrollHeight } = listInnerRef.current;
      if (scrollHeight < scrollPosition + 800) {
        setScrollTraking(false)
        setPageValue(pageValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, indexedData]);

  useEffect(() => {

    if (pageValue > 0 && indexedData && indexedData.length === 20 * pageValue) {
      let last_id = indexedData[indexedData.length - 1]._id
      let last_date = indexedData[indexedData.length - 1].utc_date
      console.log(last_id)
      console.log('ss')
      // mainApi.getPatients({ limit: 20, last_id: prevId })
      //   .then((res) => {
      //     console.log(res.patients)
      //     setPatients(prewList => prewList.concat(res.patients))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      setPreloaderVisible(true)
      mainApi.getPatientMedicalIntakesIndexed({ user_id: patientId, limit: 20, last_id: last_id, last_date: last_date, medicine_id: medicine_id })
        .then((res) => {
          console.log(res.intakes)
          setPreloaderVisible(false)
          setIndexedIntakeData(prewList => prewList.concat(res.intakes))
        })
        .catch((err) => {
          setPreloaderVisible(false)
          console.log(err)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue, indexedData])

  function handleReload() {
    setPreloaderVisible(true)
    setPageValue(0)
    setPrevScrollPosition(0)
    setScrollPosition(0)
  }

  return (
    <div className="intakes">
      <div className="intakes__table-container">
        {medicine_id ? indexedData && indexedData.length > 0 ?
          <div className="intakes__selected-medicine">
            

            {indexedData[0].medicine.type === 'pill' && <img className="intakes__medicine-image" src={pillIcon} alt='' />}
            {indexedData[0].medicine.type === 'injection' && <img className="intakes__medicine-image" src={injectionIcon} alt='' />}
            {indexedData[0].medicine.type === 'syrup' && <img className="intakes__medicine-image" src={syrupIcon} alt='' />}
            {indexedData[0].medicine.type === 'spray' && <img className="intakes__medicine-image" src={sprayIcon} alt='' />}
            {indexedData[0].medicine.type === 'tablet' && <img className="intakes__medicine-image" src={tabletIcon} alt='' />}
            {indexedData[0].medicine.type === 'drops' && <img className="intakes__medicine-image" src={dropsIcon} alt='' />}
            {indexedData[0].medicine.type === 'ointment' && <img className="intakes__medicine-image" src={ointmentIcon} alt='' />}
            {indexedData[0].medicine.type === 'procedure' && <img className="intakes__medicine-image" src={procedureIcon} alt='' />}

            <p className="intakes__selected-medicine-text">
              {indexedData[0].medicine.name}
            </p>
            <Link className="intakes__selected-medicine-cross-link" to={`/patients/${patientId}/intakes`} onClick={handleReload}>
              <svg className="intakes__selected-medicine-cross" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6846 1.3335L1.33291 10.6852" stroke="black" strokeWidth="2" strokeLinecap="round" />
                <path d="M1.33301 1.3335L10.6847 10.6852" stroke="black" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </Link>

          </div>
          : <></> : <></>}
        <table className="intakes__table" ref={listInnerRef}>
          <thead className="intakes__table-head">
            <tr className="intakes__table-tr-head">
              <th
                className="intakes__table-title intakes__table-title_type_date"
                colSpan="1"
              >
                Date &amp; Time
              </th>
              <th
                className="intakes__table-title intakes__table-title_type_medicine"
                colSpan="1"
              >
                Medications used
              </th>
              <th
                className="intakes__table-title intakes__table-title_type_dosage"
                colSpan="1"
              >
                Dosage
              </th>
              <th
                className="intakes__table-title intakes__table-title_type_duration"
                colSpan="1"
              >
                Dosage duration
              </th>
              <th
                className="intakes__table-title intakes__table-title_type_status"
                colSpan="1"
              >
                Intake status
              </th>
            </tr>
          </thead>

          <tbody className="intakes__table-body">
            {indexedData && indexedData.length !== 0
              ? indexedData.map((intake, i) => (
                <tr className="intakes__table-tr-body" key={i}>
                  <th className="intakes__table-th-body intakes__table-th-body_type_date">
                    <p className="intakes__table-date-text">{moment(`${intake.utc_date}+00:00`).format('DD.MM.YYYY HH:mm:ss')}</p>
                  </th>
                  <th className="intakes__table-th-body intakes__table-th-body_type_medicine">
                    <Link className="intakes__medicine-box" title={intake.medicine.name} to={`/patients/${patientId}/intakes/${intake.medicine._id}`} onClick={handleReload} >

                      {intake.medicine.type === 'pill' && <img className="intakes__medicine-image" src={pillIcon} alt='' />}
                      {intake.medicine.type === 'injection' && <img className="intakes__medicine-image" src={injectionIcon} alt='' />}
                      {intake.medicine.type === 'syrup' && <img className="intakes__medicine-image" src={syrupIcon} alt='' />}
                      {intake.medicine.type === 'spray' && <img className="intakes__medicine-image" src={sprayIcon} alt='' />}
                      {intake.medicine.type === 'tablet' && <img className="intakes__medicine-image" src={tabletIcon} alt='' />}
                      {intake.medicine.type === 'drops' && <img className="intakes__medicine-image" src={dropsIcon} alt='' />}
                      {intake.medicine.type === 'ointment' && <img className="intakes__medicine-image" src={ointmentIcon} alt='' />}
                      {intake.medicine.type === 'procedure' && <img className="intakes__medicine-image" src={procedureIcon} alt='' />}

                      <p className="intakes__medicine-text">
                        {intake.medicine.name}
                      </p>
                    </Link>
                  </th>
                  <th className="intakes__table-th-body intakes__table-th-body_type_dosage">
                    <p className="intakes__table-text">{intake.medicine.dosage} {intake.medicine.dosage_type}</p>
                  </th>
                  <th className="intakes__table-th-body intakes__table-th-body_type_duration">
                    <p className="intakes__table-text">{intake.medicine.dosage_days} {intake.medicine.dosage_days === 1 ? 'day' : 'days'}</p>
                  </th>
                  <th className="intakes__table-th-body intakes__table-th-body_type_status">
                    <div
                      className={`intakes__status-box ${intake.status === "skipped" || intake.status === "missed"
                        ? "intakes__status-box_type_skipped"
                        : ""
                        } ${intake.status === 'intaked'
                          ? "intakes__status-box_type_taken"
                          : ""
                        }`}
                    >
                      <p className="intakes__status-text">{intake.status === 'intaked' ? 'Taken' : intake.status === "skipped" ? 'Skipped' : intake.status === "missed" ? 'Missed' : ''}</p>
                    </div>
                  </th>
                </tr>
              ))
              : null}
          </tbody>
          {isPreloaderVisible ? <div className="intakes__table-preloader">
            <MiniPreloader isLinkColor={false} />
          </div> : <></>}
        </table>
      </div>


      <div className="intakes__results-block">
        <div className="intakes__total-numbers-box">
          <div className="intakes__total-box">
            <p className="intakes__total-number">
              {staticData ? staticData.user.skipped : <></>}
            </p>
            <p className="intakes__total-text">Total skips</p>
          </div>

          <div className="intakes__total-box">
            <p className="intakes__total-number">{staticData ? staticData.user.intaked : <></>}</p>
            <p className="intakes__total-text">Total taken</p>
          </div>
        </div>

        <div className="intakes__total-medicine-block">
          <p className="intakes__total-medicine-title">Medications used</p>
          <div className="intakes__medicine-used-box">
            {staticData ? staticData.medications_used.map((medicine, i) => (

              <Link className="intakes__medicine-box" key={`medicine${i}`} title={medicine.name} to={`/patients/${patientId}/intakes/${medicine._id}`} onClick={handleReload} >
                {/* <img
                  className="intakes__medicine-image"
                  src={medicine.image}
                  alt={medicine.name}
                /> */}
                {medicine.type === 'pill' && <img className="intakes__medicine-image" src={pillIcon} alt='' />}
                {medicine.type === 'injection' && <img className="intakes__medicine-image" src={injectionIcon} alt='' />}
                {medicine.type === 'syrup' && <img className="intakes__medicine-image" src={syrupIcon} alt='' />}
                {medicine.type === 'spray' && <img className="intakes__medicine-image" src={sprayIcon} alt='' />}
                {medicine.type === 'tablet' && <img className="intakes__medicine-image" src={tabletIcon} alt='' />}
                {medicine.type === 'drops' && <img className="intakes__medicine-image" src={dropsIcon} alt='' />}
                {medicine.type === 'ointment' && <img className="intakes__medicine-image" src={ointmentIcon} alt='' />}
                {medicine.type === 'procedure' && <img className="intakes__medicine-image" src={procedureIcon} alt='' />}
                <p className="intakes__medicine-text">{medicine.name}</p>
              </Link>
            )) : <></>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicationIntakes;
