import moment from "moment-timezone";
import mainApi from "../../../assets/api/MainApi";
import "./MedicalData.css";

function MedicalData({ data, userSyndromes, userSymptomes, patientId, setUserSyndromes }) {
    function handleSelectChange(e, syndrome, key, type) {
        console.log(e.target.value, syndrome)
        setUserSyndromes(prevItem => prevItem.map((item) => {
            if (item._id === syndrome._id) {
                return {
                    ...item,
                    items: item.items.map((item2) => {
                        if (item2.key === key) {
                            return {
                                ...item2,
                                value: e.target.value
                            }
                        } else return item2
                    })
                }

            } else return item
        }))
        mainApi.changeSyndrom({
            user_id: patientId, user_syndrome_id: syndrome._id, syndrome_id: syndrome.syndromes_id, data: {
                [key]: {
                    value: e.target.value,
                    type: type,
                }
            }
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className="medical-data">
            <div className="medical-data__patient-data">
                <p className="medical-data__patient-data-title">Patient data</p>
                <div className="medical-data__patient-data-item">
                    <p className="medical-data__patient-data-item-title">Country of residence:</p>
                    <p className="medical-data__patient-data-item-value">{data ? data.country : ''}</p>
                </div>
                <div className="medical-data__patient-data-item">
                    <p className="medical-data__patient-data-item-title">Weight:</p>
                    <p className="medical-data__patient-data-item-value">{data ? `${data.weight.value} ${data.weight.type}` : ''}</p>
                </div>
                <div className="medical-data__patient-data-item">
                    <p className="medical-data__patient-data-item-title">Height:</p>
                    <p className="medical-data__patient-data-item-value">{data ? `${data.height.value} ${data.height.type}` : ''}</p>
                </div>
                <div className="medical-data__patient-data-item">
                    <p className="medical-data__patient-data-item-title">Gender:</p>
                    <p className="medical-data__patient-data-item-value">{data ? data.gender.substring(0, 1).toUpperCase() + data.gender.substring(1) : ''}</p>
                </div>
                <div className="medical-data__patient-data-item">
                    <p className="medical-data__patient-data-item-title">Date of Birth:</p>
                    <p className="medical-data__patient-data-item-value">{data ? moment(data.date_of_birth).format('MM.YYYY') : ''}</p>
                </div>
            </div>
            <div className="medical-data__patient-data medical-data__patient-data_syndrome">
                <p className="medical-data__patient-data-title">Syndromes</p>
                {userSyndromes ? userSyndromes.map((syndrome) => (
                    <div className="medical-data__syndrome-item">
                        <p className="medical-data__syndrome-item-name">{syndrome.name ? syndrome.name : 'Epillesy'}</p>
                        {syndrome.items.map((card) => (
                            <div className="medical-data__syndrome-item-card">
                                <p className="medical-data__syndrome-item-card-name">{card.name}</p>
                                <div className="medical-data__syndrome-item-values">
                                    <select className="medical-data__syndrome-item-card-value-container" value={card.value} onChange={(e) => {
                                        handleSelectChange(e, syndrome, card.key, card.type)
                                    }}>
                                        {card.choose_values.map((value) => (
                                            <option value={value} className="medical-data__syndrome-item-card-value">
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                    <svg className="medical-data__arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 16L18 10.332L16.59 9L12 13.3266L7.41 9L6 10.332L12 16Z" fill="#323232" />
                                    </svg>
                                </div>



                            </div>
                        ))}
                    </div>
                ))

                    : <></>}


                { }
            </div>
            <div className="medical-data__patient-data medical-data__patient-data_syndrome">
                <p className="medical-data__patient-data-title">Symptoms</p>
                {userSymptomes ? userSymptomes.map((symptome) => (
                    <div className="medical-data__syndrome-item">
                        <p className="medical-data__syndrome-item-name">{symptome.name ? symptome.name : 'Seizure'}</p>
                        {symptome.items.map((card) => (
                            <>
                                {card.key === "seizure_number_and_duration" ?
                                    <>
                                        <div className="medical-data__syndrome-item-card">
                                            <p className="medical-data__syndrome-item-card-name">Amount</p>
                                            <p className="medical-data__syndrome-item-card-value">{card.value.amount}</p>
                                        </div>
                                        <div className="medical-data__syndrome-item-card">
                                            <p className="medical-data__syndrome-item-card-name">Duration</p>
                                            <p className="medical-data__syndrome-item-card-value">{card.value.duration.minutes} min {card.value.duration.seconds} seconds </p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="medical-data__syndrome-item-card">
                                            <p className="medical-data__syndrome-item-card-name">{card.name}</p>
                                            <p className="medical-data__syndrome-item-card-value">
                                                {card.value}
                                            </p>
                                        </div>

                                    </>
                                }
                            </>





                        ))}
                    </div>
                ))

                    : <></>}


            </div>
        </div>
    );
}

export default MedicalData;
