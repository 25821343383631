import moment from "moment-timezone";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { useEffect, useRef, useState } from "react";
import "./Notes.css";
import DeliteNotePopup from "../DeliteNotePopup/DeliteNotePopup";
import mainApi from "../../../assets/api/MainApi";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";


function Notes({ patientId, notes, setNotes }) {


    const [timerValue, setTimerValue] = useState(0);


    useEffect(() => {
        const timer = setInterval(() => {
            setTimerValue(timerValue + 1)
            clearInterval(timer)
        }, 1000);
    }, [timerValue])


    const [isAdding, setIsAdding] = useState(false)
    const [newNoteTitle, setNewNoteTitle] = useState('')
    const [newNoteTitleValidity, setNewNoteTitleValidity] = useState({})

    const [newNoteText, setNewNoteText] = useState('')
    const [newNoteTextValidity, setNewNoteTextValidity] = useState({})

    const [isSavingNewNote, setSavingNewNote] = useState(false)


    function handleNewNoteTitleChange(e) {
        let inputValue = e.target.value
        if (inputValue) {
            setNewNoteTitleValidity({
                errorMassage: '',
                validState: true
            })
        } else {
            setNewNoteTitleValidity({
                errorMassage: 'Can not be empty',
                validState: false
            })
        }
        setNewNoteTitle(inputValue)
    }

    function handleNewNoteTextChange(e) {
        let inputValue = e.target.value
        if (inputValue) {
            setNewNoteTextValidity({
                errorMassage: '',
                validState: true
            })
        } else {
            setNewNoteTextValidity({
                errorMassage: 'Can not be empty',
                validState: false
            })
        }
        setNewNoteText(inputValue)
    }

    function handleCancelNewNote() {
        setIsAdding(false)
        setNewNoteTitle('')
        setNewNoteTitleValidity({})
        setNewNoteText('')
        setNewNoteTextValidity({})
    }

    const [isSaveNewActive, setSaveNewActive] = useState(false)
    useEffect(() => {
        if (newNoteTextValidity.validState && newNoteTitleValidity.validState) {
            setSaveNewActive(true)
        } else {
            setSaveNewActive(false)
        }
    }, [newNoteTextValidity, newNoteTitleValidity])


    function handleSaveNewCard() {
        setSavingNewNote(true)
        setTimeout(() => {
            setNotes(oldArray => [
                {
                    "_id": moment().format('x'),
                    "date": moment(),
                    "text": newNoteText,
                    "name": newNoteTitle,
                    "edited": false
                }
                , ...oldArray])
            setSavingNewNote(false)
            setIsAdding(false)
            setNewNoteTitle('')
            setNewNoteTitleValidity({})
            setNewNoteText('')
            setNewNoteTextValidity({})
        }, 3000);

    }


    const [redactingCardId, setRedactingCardId] = useState('')
    const [redactingNoteTitle, setRedactingNoteTitle] = useState('')
    const [redactingNoteTitleValidity, setRedactingNoteTitleValidity] = useState({})

    const [redactingNoteText, setRedactingNoteText] = useState('')
    const [redactingNoteTextValidity, setRedactingNoteTextValidity] = useState({})

    const [isRedactingSaveActive, setRedactingSaveActive] = useState(true)

    const [isRedactingPreloaderVisible, setRedactingPreloaderVisible] = useState(false)



    function handleRedactingCard(card) {

        setRedactingNoteTitle(card.name)
        setRedactingNoteTitleValidity({
            errorMassage: '',
            validState: true
        })
        setRedactingNoteText(card.text)
        setRedactingNoteTextValidity({
            errorMassage: '',
            validState: true
        })
        setTimeout(() => {
            setRedactingCardId(card._id)
        }, 100);
    }

    function handleCancelRedactingNote() {
        setRedactingNoteTitle('')
        setRedactingNoteTitleValidity({})
        setRedactingNoteText('')
        setRedactingNoteTextValidity({})
        setRedactingCardId('')
    }

    function handleRedactTitle(e) {
        let inputValue = e.target.value
        if (inputValue) {
            setRedactingNoteTitleValidity({
                errorMassage: '',
                validState: true
            })
        } else {
            setRedactingNoteTitleValidity({
                errorMassage: 'Can not be empty',
                validState: false
            })
        }
        setRedactingNoteTitle(inputValue)
    }

    function handleRedactText(e) {
        let inputValue = e.target.value
        if (inputValue) {
            setRedactingNoteTextValidity({
                errorMassage: '',
                validState: true
            })
        } else {
            setRedactingNoteTextValidity({
                errorMassage: 'Can not be empty',
                validState: false
            })
        }
        setRedactingNoteText(inputValue)
    }



    useEffect(() => {
        if (redactingNoteTextValidity.validState && redactingNoteTitleValidity.validState && redactingCardId) {
            setRedactingSaveActive(true)
        } else {
            setRedactingSaveActive(false)
        }
    }, [redactingNoteTextValidity, redactingNoteTitleValidity, redactingCardId])

    function handleSaveRedactedCard() {
        setRedactingPreloaderVisible(true)
        setTimeout(() => {
            setNotes(oldArray => oldArray.map((item) => {
                if (item._id === redactingCardId) return {
                    "_id": moment().format('x'),
                    "date": moment(),
                    "text": redactingNoteText,
                    "name": redactingNoteTitle,
                    "edited": false
                }
                else return item
            }))
            setRedactingPreloaderVisible(false)
            setRedactingNoteTitle('')
            setRedactingNoteTitleValidity({})
            setRedactingNoteText('')
            setRedactingNoteTextValidity({})
            setRedactingCardId('')
        }, 3000);

    }

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [noteIdToDelite, setNoteIdToDelite] = useState('')
    const [isDelitePreloaderVisible, setDelitePreloaderVisible] = useState(false);

    function handleDeliteClick() {
        console.log(`HANDLE DELITE NOTE ID ${noteIdToDelite}`)
        setDelitePreloaderVisible(true)
        setTimeout(() => {
            setNotes(oldArray => oldArray.filter((item) => {
                if (item._id === noteIdToDelite) return false
                else return true
            }))
            setPopupOpen(false)
            setTimeout(() => {
                setDelitePreloaderVisible(false)
                setNoteIdToDelite('')
            }, 500);
        }, 3000);
       

    }

    function handleCancelPopup() {
        setPopupOpen(false)
        setNoteIdToDelite('')
    }



    const listInnerRef = useRef();

    const [pageValue, setPageValue] = useState(0);
    const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
    const [scrollPosition, setScrollPosition] = useState(0);
    // const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    const [scrollTraking, setScrollTraking] = useState(true);
    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {

        if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && notes && notes.length > 0) {
            setPrevScrollPosition(scrollPosition)
            const { scrollHeight } = listInnerRef.current;
            if (scrollHeight < scrollPosition + 800) {
                setScrollTraking(false)
                setPageValue(pageValue + 1)
                setTimeout(() => {
                    setScrollTraking(true)
                }, 500);
            }
        }
    }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, notes]);

    useEffect(() => {

        if (pageValue > 0 && notes && notes.length === 12 * pageValue) {
            let prevId = notes[notes.length - 1]._id
            console.log(prevId)
            console.log('ss')
            // mainApi.getPatients({ limit: 20, last_id: prevId })
            //   .then((res) => {
            //     console.log(res.patients)
            //     setPatients(prewList => prewList.concat(res.patients))
            //   })
            //   .catch((err) => {
            //     console.log(err)
            //   })
            mainApi.getNotes({ user_id: patientId, limit: 12, last_id: prevId })
                .then((res) => {
                    setNotes(prewList => prewList.concat(res.notes))
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageValue, notes])

    return (
        <div className="notes">
            {!isAdding ?
                <button className="notes__add-btn" onClick={() => setIsAdding(true)}>
                    <svg className="notes__add-btn-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.06 3.58988L20.41 4.93988C21.2 5.71988 21.2 6.98988 20.41 7.76988L7.18 20.9999H3V16.8199L13.4 6.40988L16.23 3.58988C17.01 2.80988 18.28 2.80988 19.06 3.58988ZM5 18.9999L6.41 19.0599L16.23 9.22988L14.82 7.81988L5 17.6399V18.9999Z" fill="#072019" />
                    </svg>
                    <p className="notes__add-btn-text">Add note</p>
                </button>
                : <></>}
            {isAdding ?
                <div className="notes__new-note">
                    <p className="notes__note-date">{moment().format('DD.MM.YYYY HH:mm')}</p>
                    <input className={`notes__note-name-input `} placeholder="Name your note" name="newnote name" type="text" value={newNoteTitle} onChange={handleNewNoteTitleChange} minLength="0" ></input>
                    <TextareaAutosize className={`notes__note-textarea`} placeholder="Note..." name="note textarea" type="text" value={newNoteText} onChange={handleNewNoteTextChange} minLength="0" maxLength="350" ></TextareaAutosize>

                    <div className="notes__note-btns">
                        <button className="notes__note-btn notes__note-btn_type_cancel" onClick={handleCancelNewNote}>
                            <p className="notes__note-btn-text notes__note-btn-text_type_cancel">Cancel</p>
                        </button>
                        <button className={`notes__note-btn notes__note-btn_type_save ${!isSaveNewActive ? 'notes__note-btn_inactive' : ''}`} onClick={() => {
                            if (isSaveNewActive) {
                                handleSaveNewCard()
                            }
                        }}>
                            {isSavingNewNote ?
                                <MiniPreloader isLinkColor={true} />
                                :
                                <p className="notes__note-btn-text notes__note-btn-text_type_save">Save</p>
                            }

                        </button>
                    </div>
                </div> : <></>}
            <div className="notes__cards" ref={listInnerRef}>
                {notes && notes.length > 0 ? notes.map((item, i) => (
                    <div className="notes__card" key={`noteCard${item && item._id && item._id}`}>
                        <div className="notes__card-date-and-btns">
                            <p className="notes__card-date">{item && item.date ? moment(item.date).format('DD.MM.YYYY HH:mm') : ''}</p>
                            {item && redactingCardId !== item._id ?
                                <div className="notes__card-redact-btns">
                                    <svg className="notes__card-redact-btn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                        handleRedactingCard(item)
                                    }}>
                                        <path d="M3 17.2498V20.9998H6.75L17.81 9.93984L14.06 6.18984L3 17.2498ZM21.41 6.33984L17.66 2.58984L15.13 5.12984L18.88 8.87984L21.41 6.33984V6.33984Z" fill="#006B58" />
                                    </svg>
                                    <svg className="notes__card-redact-btn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                        setNoteIdToDelite(item && item._id && item._id)
                                        setPopupOpen(true)
                                    }}>
                                        <path d="M6 21H18V7H6V21ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#006B58" />
                                    </svg>
                                </div>
                                : <></>}

                        </div>
                        <input disabled={item && redactingCardId !== item._id} className={`notes__card-name-input `} placeholder="Name your note" name="newnote name" type="text" value={item && redactingCardId !== item._id ? item.name : redactingNoteTitle} onChange={handleRedactTitle} minLength="0" ></input>
                        <TextareaAutosize disabled={item && redactingCardId !== item._id} className={`notes__card-textarea`} placeholder="Note..." name="note textarea" type="text" value={item && redactingCardId !== item._id ? item.text : redactingNoteText} onChange={handleRedactText} minLength="0" maxLength="350" ></TextareaAutosize>
                        {item && redactingCardId === item._id ?
                            <div className="notes__card-btns">
                                <button className="notes__card-btn notes__card-btn_type_cancel" onClick={handleCancelRedactingNote}>
                                    <p className="notes__card-btn-text notes__card-btn-text_type_cancel">Cancel</p>
                                </button>
                                <button className={`notes__card-btn notes__card-btn_type_save ${!isRedactingSaveActive ? 'notes__card-btn_inactive' : ''}`} onClick={() => {
                                    if (isRedactingSaveActive) {
                                        handleSaveRedactedCard()
                                    }
                                }}>
                                    {isRedactingPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : <p className="notes__card-btn-text notes__card-btn-text_type_save">Save</p>}
                                </button>
                            </div> :
                            <>
                                {item && item.edited ? <p className="notes__card-edited">(Edited)</p> : <></>}
                            </>}
                    </div>
                )) : <></>}
            </div>
            <DeliteNotePopup
                popup="delite"
                isPreloaderVisible={isDelitePreloaderVisible}
                isPreloaderLinkColor={false}
                {...{ isPopupOpen, setPopupOpen }}
                onClick={handleDeliteClick}
                onCancel={handleCancelPopup}
            >
                <svg className="popup__warning-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.47012 21.0002H19.5301C21.0701 21.0002 22.0301 19.3302 21.2601 18.0002L13.7301 4.99018C12.9601 3.66018 11.0401 3.66018 10.2701 4.99018L2.74012 18.0002C1.97012 19.3302 2.93012 21.0002 4.47012 21.0002ZM12.0001 14.0002C11.4501 14.0002 11.0001 13.5502 11.0001 13.0002V11.0002C11.0001 10.4502 11.4501 10.0002 12.0001 10.0002C12.5501 10.0002 13.0001 10.4502 13.0001 11.0002V13.0002C13.0001 13.5502 12.5501 14.0002 12.0001 14.0002ZM13.0001 18.0002H11.0001V16.0002H13.0001V18.0002Z" fill="#323232" />
                </svg>

                <p className="popup__text popup__text_centered">
                    Do you really want<br />to delete note?
                </p>
            </DeliteNotePopup>
        </div>
    );
}

export default Notes;
