import { useEffect, useRef, useState } from "react";
import { WEEK_LIST } from "../../../assets/utils/constants";
import { CLOSE_ICON, PATIENT_LINK_ICON } from "../../../assets/utils/icons";

import pillIcon from '../../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../../assets/images/main/med_type/drops.png'
import procedureIcon from '../../../assets/images/main/med_type/procedure.png'
import ointmentIcon from '../../../assets/images/main/med_type/ointment.png'


import "./MedicationPopup.css";
import { Link } from "react-router-dom";
import mainApi from "../../../assets/api/MainApi";
import moment from "moment-timezone";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";




function MedicationPopup({ isOpen, medicine, setTypeInfo, closePopup, patientId, indexedIntakes, setIndexedIntakes }) {
  const overlay = useRef();

  const listInnerRef = useRef();

  const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
  const [pageValue, setPageValue] = useState(0);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closePopup();
        setTimeout(() => {
          listInnerRef.current.scrollTo(0, 0)
          setPrevScrollPosition(0)
          setPageValue(0)
        }, 400);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });







  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [scrollTraking, setScrollTraking] = useState(true);
  // const handleScroll = () => {

  //   const position = listInnerRef.current.scrollTop;
  //   console.log(listInnerRef.current.scrollTop)

  //   setScrollPosition(position);
  // };




  useEffect(() => {
    // console.log(prevScrollPosition)
    if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && indexedIntakes && indexedIntakes.length > 0) {
      setPrevScrollPosition(scrollPosition)
      const { scrollHeight, clientHeight } = listInnerRef.current;
      // console.log(scrollHeight - clientHeight - 160, scrollPosition,)
      if (scrollHeight - clientHeight - 160 <= scrollPosition) {
        console.log('e')
        setScrollTraking(false)
        setPageValue(pageValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, indexedIntakes]);

  useEffect(() => {

    if (pageValue > 0 && indexedIntakes && indexedIntakes.length === 15 * pageValue) {
      let prevId = indexedIntakes[indexedIntakes.length - 1]._id
      console.log(indexedIntakes[indexedIntakes.length - 1])
      let lastDate = indexedIntakes[indexedIntakes.length - 1].intake_time
      console.log(prevId)
      console.log('ss')
      // mainApi.getPatients({ limit: 20, last_id: prevId })
      //   .then((res) => {
      //     console.log(res.patients)
      //     setPatients(prewList => prewList.concat(res.patients))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      setPreloaderVisible(true)
      mainApi.getMedicineInfoIndexed({ medication_id: medicine._id, user_id: patientId, limit: 15, last_id: prevId, last_date: lastDate })
        .then((res) => {
          console.log(res.medicine_intakes)
          setIndexedIntakes(prewList => prewList.concat(res.medicine_intakes))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setPreloaderVisible(false)
        })

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue, indexedIntakes])

  function handleeScroll(e) {
    // console.log(e.target.scrollTop)
    setScrollPosition(e.target.scrollTop);
  }

  function createTextReason() {
    let array = []
    if (medicine.reason.aviability) array.push('Aviability')
    if (medicine.reason.cost) array.push('Cost')
    if (medicine.reason.drugs_interaction) array.push('Drugs interaction')
    if (medicine.reason.form_of_use_reason.bad_flavor) array.push('Bad flavor')
    if (medicine.reason.form_of_use_reason.difficulty_swallowing) array.push('Difficulty swallowing')
    if (medicine.reason.form_of_use_reason.other_form_of_use) array.push('Other form of use')
    if (medicine.reason.lack_efficacy) array.push('Lack efficacy')
    if (medicine.reason.medicine_deleted) array.push('Medicine deleted')
    if (medicine.reason.side_effects) array.push('Side effects')
    if (medicine.reason.other_reason) array.push(medicine.reason.other_reason)
    return array
  }

  return (
    <div className={`medicine-popup ${isOpen ? "medicine-popup_opened" : ""}`} key={medicine && medicine._id}>
      {medicine && Object.keys(medicine).length !== 0 ? (
        <div className="medicine-popup__container">
          <div className="medicine-popup__heading-box">
            <div className="medicine-popup__title-box">
              <div className="medicine-popup__icon-box">

                {medicine.type === 'pill' && <img className="medicine-popup__icon" src={pillIcon} alt='' />}
                {medicine.type === 'injection' && <img className="medicine-popup__icon" src={injectionIcon} alt='' />}
                {medicine.type === 'syrup' && <img className="medicine-popup__icon" src={syrupIcon} alt='' />}
                {medicine.type === 'spray' && <img className="medicine-popup__icon" src={sprayIcon} alt='' />}
                {medicine.type === 'tablet' && <img className="medicine-popup__icon" src={tabletIcon} alt='' />}
                {medicine.type === 'drops' && <img className="medicine-popup__icon" src={dropsIcon} alt='' />}
                {medicine.type === 'ointment' && <img className="medicine-popup__icon" src={ointmentIcon} alt='' />}
                {medicine.type === 'procedure' && <img className="medicine-popup__icon" src={procedureIcon} alt='' />}

              </div>
              <p className="medicine-popup__name">{medicine.name}</p>
            </div>

            <button
              className="medicine-popup__close-button"
              type="button"
              aria-label="Close popup"
              onClick={() => {
                closePopup()
                setTimeout(() => {
                  listInnerRef.current.scrollTo(0, 0)
                  setPrevScrollPosition(0)
                  setPageValue(0)
                }, 400);

              }}
            >
              {CLOSE_ICON}
            </button>
          </div>

          <div className="medicine-popup__content-box">
            <ul className="medicine-popup__main-info">
              <li className="medicine-popup__info-item">
                <p className="medicine-popup__info-title">Dosage</p>
                <p className="medicine-popup__info-text">{medicine.dosage} {medicine.dosage_type}, 1 dose</p>
              </li>

              <li className="medicine-popup__info-item">
                <p className="medicine-popup__info-title">Helps from</p>
                <p className="medicine-popup__info-text">
                  {medicine.syndrome_text}
                </p>
              </li>

              {!medicine.still_taking ? (
                <li className="medicine-popup__info-item">
                  <p className="medicine-popup__info-title">
                    Reasons for discontinuation
                  </p>
                  <p className="medicine-popup__info-text">
                    {/* {medicine.reason.join(", ")} */}

                    {createTextReason().join(", ")}
                  </p>
                </li>
              ) : null}

              <li className="medicine-popup__info-item">
                <p className="medicine-popup__info-title">History of intakes</p>
                <Link
                  className="medicine-popup__info-link"
                  to={`/patients/${patientId}/intakes/${medicine._id}`}
                  onClick={() => setTypeInfo("intakes")}
                >
                  <p className="medicine-popup__link-text">Link</p>
                  <div className="medicine-popup__link-icon">
                    {PATIENT_LINK_ICON}
                  </div>
                </Link>
              </li>
            </ul>

            <div className="medicine-popup__intakes-info">
              <div className="medicine-popup__info-item">
                <p className="medicine-popup__info-title">Frequency</p>
                <p className="medicine-popup__info-text">
                  {medicine.frequency === 'one_time_intake' ? 'One time intake' : ''}
                  {medicine.frequency === 'everyday' ? 'Everyday' : ''}
                  {medicine.frequency === 'specific_days' ? 'Specific days' : ''}
                </p>
              </div>

              {medicine.frequency === "specific_days" ? (
                <ul className="medicine-popup__days-list">
                  {WEEK_LIST.map((day) => (
                    <li
                      className={`medicine-popup__day ${medicine.days_list[day.toLowerCase()]
                        ? "medicine-popup__day_selected"
                        : ""
                        }`}
                      key={day}
                    >
                      <p className="medicine-popup__day-text">{day}</p>
                    </li>
                  ))}
                </ul>
              ) : null}

              {medicine.frequency !== "one_time_intake" ? (
                <div className="medicine-popup__info-item">
                  <p className="medicine-popup__info-title">
                    Number of intakes per day
                  </p>
                  <p className="medicine-popup__info-text">
                    {medicine.total_intakes}
                  </p>
                </div>
              ) : null}

              {medicine.frequency !== "one_time_intake" && indexedIntakes && indexedIntakes.length > 0 ? (
                <div className="medicine-popup__intakes-box">
                  <p className="medicine-popup__intakes-title">Intake time</p>
                  <ul className="medicine-popup__intakes-list" ref={listInnerRef} id="listInner" onScroll={handleeScroll}>
                    {indexedIntakes.map((item, i) => (
                      <li
                        className="medicine-popup__intake-item"
                        key={`intake${i}`}
                      >
                        <p className="medicine-popup__intake-time">
                          {moment(item.intake_time).format('HH:mm')}
                        </p>
                        <p className="medicine-popup__intake-text">
                          {item.dose_amount} dose
                          {/* {item._id} */}
                        </p>
                      </li>
                    ))}
                    {isPreloaderVisible ?
                      <li className="medicine-popup__intake-item-preloader">
                        <MiniPreloader />
                      </li>
                      : <></>}

                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div
        className="medicine-popup__overlay"
        ref={overlay}
        onTouchStart={() => {
          closePopup()
          setTimeout(() => {
            listInnerRef.current.scrollTo(0, 0)
            setPrevScrollPosition(0)
            setPageValue(0)
          }, 400);
        }}
      />
    </div>
  );
}

export default MedicationPopup;
